import * as React from 'react';
// import _, {debounce} from '@types/lodash';


//* MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SvgIcon from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import Error from '../components/Error';

//* Icons

//* Components

//* Variables
const error = {
  title: 'Error 404',
  description: 'The page requested could not be found.',
  image: '../../img/logos/aspen-logo.svg',
  imageLabel: 'Aspen Logo',
};

export default function NotFound() {
  return (
    <Container maxWidth="lg">
      <main style={{ marginTop: '2vh' }}>
        <Error error={error} />
      </main>
    </Container>
  );
}
