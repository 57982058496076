import * as React from 'react';
import axios from 'axios';
// import _, {debounce} from '@types/lodash';


//* MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SvgIcon from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import CircleLoader from '@mui/material/CircularProgress';

//* Colours
import { grey } from '@mui/material/colors';

//* Icons

//* Components

export default function Login() {
  const [state, setState] = React.useState({
    user: {
      email: "",
      password: ""
    },
    loading: false
  });

  //* React Event List
  // React.SyntheticEvent
  // React.FormEvent
  // React.ChangeEventHandler<HTMLInputElement>
  // React.SyntheticEvent<HTMLInputElement>
  //* End

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    //* DEFAULT
    // const data = new FormData(e.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    //TODO
    //* MY METHOD
    setState({ ...state, loading: true });
    var data = {
      username: state.user.email,
      password: state.user.password,
    };

    //!
    console.log(data);
    //!

    var headers = {};
    axios
      .post("localhost:8000/login", data, headers)
      .catch((err) => {
        if (err) {
          // console.log(err.response);
          // this.warning.message ="Your username or password was incorrect."

          // this.notification.icon = "fa-times";
          // this.notification.text = "text-danger";
          // this.notification.color = "red";
          // this.notification.display = true;
          // this.notification.message = "Log in failed.";
        }
        // if (err.response.status === 401) {
        //   this.warning.message =
        //     "No match found for credentials provided.";
        // }
        // if (err.response.status === 429) {
        //   this.warning.message =
        //     "Please try again in 5 minutes";
        // }
        // this.loading = false;
      })
      .then((res) => {
        // console.log(res);
        // if (res && res.status && res.status == 200) {
        //   this.setUser(res.data);

        //   // TODO ADD NOTIF TO DASHBOARD
        //   this.notification.icon = "fa-check-circle";
        //   this.notification.text = "text-success";
        //   this.notification.color = "green";
        //   this.notification.message =
        //     "Logged in as " + res.data.username + ".";
        //   this.notification.display = true;
        // }
      });
  }

  const loginChangeHandler = (e: any, prop: string) => {
    if (prop == 'email') {
      setState({
        ...state,
        user: {
          ...state.user,
          email: e.target.value,
        }
      });
    } else {
      setState({
        ...state,
        user: {
          ...state.user,
          password: e.target.value,
        }
      });
    }
  }

  return (
    <Container maxWidth="lg">
      <main style={{ marginTop: '2vh' }}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ width: 100, height: 100, m: 1, bgcolor: 'secondary.main' }}> */}
            {/* <LockOutlinedIcon /> */}
            {/* <CardMedia
                component="img"
                sx={{ width: 55, display: { sm: 'block' }}}
                image="../../img/logos/icon-transparent.png"
                alt="Aspen Logo"
                /> */}
            {/* </Avatar> */}
            {/* */}
            <Box component="form" method="POST" onSubmit={(e: React.FormEvent) => { submitHandler(e) }} noValidate sx={{ mt: 1 }}>
              <Paper sx={{ p: 1, mb: 1 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <CardMedia
                      component="img"
                      sx={{ width: 35, display: { sm: 'block' } }}
                      image="../../img/logos/aspen-logo.svg"
                      alt="Aspen Logo"
                    />
                  </Grid>
                  <Grid item>
                    <Typography sx={{}} component="h1" variant="h5">
                      Log In
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <TextField
                value={state.user.email}
                onChange={(e) => loginChangeHandler(e, 'email')}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="username"
                autoFocus
              />
              <TextField
                value={state.user.password}
                onChange={(e) => loginChangeHandler(e, 'password')}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onChange={(e) => { submitHandler(e) }}
              >
                {
                  !state.loading ? 'Sign In' : <CircleLoader sx={{ color: grey[900] }} />
                }
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* TODO */}
                  {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </main>
    </Container>
  );
}
