import * as React from 'react';

//* MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

//* Components
import HeaderImage from '../components/HeaderImage';

//* Variables
let currentHeader = 0;
let headerTitle = 'An Enterprise Network Solution';
let headerDesc = 'Your networking journey begins here';

let randDesc = Math.random();
if (headerDesc == "Your networking journey begins here") {
   if (randDesc <= .33) {
      headerDesc = "Enterprise networking made easy.";
   } else if (randDesc <= .66) {
      headerDesc = "Your networking journey begins here.";
   } else {
      headerDesc = "Join us. Improve your productivity.";
   }
}

const HeaderImageProps = {
   key: currentHeader,
   title: headerTitle,
   description: headerDesc,
   // image: 'https://source.unsplash.com/random',
   image: '../../img/plexus.png',
   imageText: 'An Enterprise Network Solution',
   linkText: 'Read more... ',
   video: true,
};

export default function Home() {
   return (
      <div>
         <HeaderImage header={HeaderImageProps} />
         <Container maxWidth={false}>
            <main>
               <Grid container spacing={4}>
                  {/* {TODO} */}
               </Grid>
               <Grid container spacing={5} sx={{ mt: 3 }}>
                  {/* TODO */}
               </Grid>
            </main>
         </Container>
      </div>
   );
}