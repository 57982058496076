import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';

//* Components
import MainFeaturedPost from '../components/MainFeaturedPost';
import HeaderImage from '../components/HeaderImage';
import FeaturedPost from '../components/FeaturedPost';
import Sidebar from '../components/Sidebar';

//* Variables
const mainFeaturedPost = {
  title: 'Our Technology',
  description:
    "TODO ADD SOME INFORMATION HERE",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
};

const featuredPosts = [
  {
    title: 'Network Compliance',
    // date: 'Nov 12',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
  {
    title: 'Automation',
    // date: 'Nov 11',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
  {
    title: 'Syslogging',
    // date: 'Nov 11',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
  {
    title: 'Configuration from the Cloud',
    // date: 'Nov 11',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
  {
    title: 'Cloud backups',
    // date: 'Nov 11',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
  {
    title: 'Troubleshooting & Diagnostics',
    // date: 'Nov 11',
    description:
      `Aspen features 
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      `,
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
    readMore: false
  },
];

// const sidebar = {
//   title: 'About',
//   description:
//     'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
//   archives: [
//     { title: 'March 2020', url: '#' },
//     { title: 'February 2020', url: '#' },
//     { title: 'January 2020', url: '#' },
//     { title: 'November 1999', url: '#' },
//     { title: 'October 1999', url: '#' },
//     { title: 'September 1999', url: '#' },
//     { title: 'August 1999', url: '#' },
//     { title: 'July 1999', url: '#' },
//     { title: 'June 1999', url: '#' },
//     { title: 'May 1999', url: '#' },
//     { title: 'April 1999', url: '#' },
//   ],
// };

export default function Technology() {
  return (
    <Container maxWidth="lg">
      <main style={{ marginTop: '2vh' }}>
        <MainFeaturedPost post={mainFeaturedPost} />
        <Grid container spacing={4}>
          {featuredPosts.map((post) => (
            // TODO REPLACE KEY WITH AN ID
            <FeaturedPost key={post.title} post={post} />
          ))}
        </Grid>
        {/* <Grid container spacing={5} sx={{ mt: 3 }}>
        </Grid> */}
      </main>
    </Container>
  );
}
