import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';

//* Animations
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';

//* Icons
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

interface HeaderImageProps {
  header: {
    title?: string;
    description?: string;
    linkText?: string;
    image: string;
    imageText: string;
    video: boolean;
  };
}

interface HeaderImageState {
    headerText: Array<Object>,
    currentHeader: number
}


export default function HeaderImage(props: HeaderImageProps /**, state: HeaderImageState*/) {
  const { header } = props;
  const [state, setState] = React.useState({ currentHeader: 0 });
  var transition: any;
  const headerText = [
    {
      title: 'An Enterprise Network Solution',
      description: 'Your networking journey begins here'
    },
    {
      title: 'Manage your networks from the Cloud',
      description: 'Using our platform you\'ll be able to perform all your usual networking needs without the hassle!'
    },
    {
      title: 'Simple and secure access to your networks',
      description: 'Our platform allows you to simply and securely manage your networks without jumping through pesky authentication hoops.'
    },
    {
      title: 'Networking made easy through automation',
      description: 'Managing networks has never been so easy.'
    },
  ];

  const titleForward = () => {
    if (state.currentHeader == (headerText.length-1)) {
        setState({ currentHeader: 0 });
    } else {
        setState(prevState => {
        return { currentHeader: prevState.currentHeader + 1 }
      })
    }
  }

  const titleBack = () => {
    if (state.currentHeader == 0) {
      setState({
        currentHeader: (headerText.length-1),
      });
    } else {
        setState(prevState => {
        return { currentHeader: prevState.currentHeader - 1 }
      })
    }
  };

  const changePageHandler = (e: any, page: any) => {
    // console.log(e);
    // console.log(page);
    setState({
      currentHeader: (page-1),
    });
  }

  // https://www.toptal.com/react/react-hooks-typescript-example

  //* Transition
  React.useEffect(() => {
    const timer = setTimeout(() => {
      titleForward();
    }, 15000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Paper
      sx={{
        display: { xs: 'none', sm: 'block' },
        overflow: 'hidden',
        height: '35vh',
        position: 'relative',
        // backgroundColor: 'grey.800',
        background: 'rgba(222, 152, 0, 1)',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'tiled',
        backgroundPosition: 'center',
        backgroundImage: !header.video ? `url(${header.image})`: null,
      }}
    >
      {
        header.video
          ? <video
            className="opacity-80"
            src="../../video/plexus.webm"
            autoPlay
            loop
            muted
          />
        :
          null
      }
    
      
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container justifyContent="center" sx={{
          position: 'absolute',
          top: 0,
      }}>
        <Grid item md={6} >
          <Box justifyContent="center" 
            sx={{
              textAlign: 'center',
              position: 'relative',
              py: { xs: 3, md: 6 },
              px: { sm: 3, md: 3 },
            }}
          >
            {/* TODO REMOVE WHEN DONE */}
            {/* {state.currentHeader} */}
            <IconButton sx={{ position: 'absolute', left: '-2vw', top: '15vh' }}
              onClick={() => {
                 titleBack();
              }}
            >
            <ChevronLeft/>
            </IconButton>
            <Pagination sx={{
              textAlign: 'center',
              justifyContent: 'center',
              pb: {sm: 2}
            }}
              onChange={changePageHandler}
              page={state.currentHeader + 1} count={headerText.length} size="small" color="primary" hidePrevButton hideNextButton
            />
            <Fade in={true} style={{ transitionDelay: '500ms' }}>
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                {state.currentHeader == 0 ?
                  header.title ?? headerText[state.currentHeader].title 
                  :
                  headerText[state.currentHeader].title
                }
              </Typography>
            </Fade>
            <Typography variant="h5" color="inherit" paragraph>
              {
                state.currentHeader == 0 ?
                header.description ?? headerText[state.currentHeader].description
                :
                headerText[state.currentHeader].description
              }
              {/* {headerText[state.currentHeader].description} */}
            </Typography>
            <Link variant="subtitle1" href="/technology">
              {header.linkText ?? 'Read more...'}
            </Link>
        
            <IconButton sx={{ position: 'absolute', right: '-2vw', top: '15vh' }}
              onClick={() => {
                titleForward();
              }}
            >
              <ChevronRight/>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
