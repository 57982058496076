import * as React from 'react';

//* MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//* Components
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

//* Variables
let currentHeader = 0;
let headerTitle = 'An Enterprise Network Solution';
let headerDesc = 'Your networking journey begins here';

let randDesc = Math.random();
if (headerDesc == "Your networking journey begins here") {
   if (randDesc <= .33) {
      headerDesc = "Enterprise networking made easy.";
   } else if (randDesc <= .66) {
      headerDesc = "Your networking journey begins here.";
   } else {
      headerDesc = "Join us. Improve your productivity.";
   }
}

interface TemplateProps {
   Page?: React.ReactNode
};

const links = [
   { title: 'Technology', url: '/technology' },
   { title: 'Pricing', url: '/pricing' },
   { title: 'About', url: '/about' },
   { title: 'Contact Us', url: '/contact' },
]

export default function AspenTemplate(props: TemplateProps) {
   const { Page } = props;

   return (
      <div>
         <Navigation links={links} />
         {Page}
         <Footer />
      </div>
   );
}