import * as React from 'react';

//* MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';

//* Components
import MainFeaturedPost from '../components/MainFeaturedPost';

//* Variables
const mainFeaturedPost = {
  title: 'Contact Us',
  description:
    "For assistance or queries involving Aspen you can contact us via this page.",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
};

export default function Contact() {
  return (
    <Container maxWidth="lg">
      <main style={{ marginTop: '2vh' }}>
        <MainFeaturedPost post={mainFeaturedPost} />
        <Grid container>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Grid>
      </main>
    </Container>
  );
}
