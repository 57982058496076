import * as React from 'react';
import {
  BrowserRouter,
  Link,
  Routes,
  Route
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

//* MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import Slider from '@mui/material/Slider';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//* Theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#DE9800',
    },
    secondary: {
      main: '#eb9c5c',
    }
  }
});

//* Components
import Header from './components/Navigation';
import Footer from './components/Footer';


//* Pages
import Home from './containers/Home';
import Technology from './containers/Technology';
import About from './containers/About';
import Contact from './containers/Contact';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Pricing from './containers/Pricing';
import NotFound from './containers/NotFound';
import AspenTemplate from './containers/AspenTemplate';

//* App
import Dashboard from './containers/Dashboard';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<AspenTemplate Page={<Home />} />} />
        <Route path="/technology" element={<AspenTemplate Page={<Technology />} />} />
        <Route path="/about" element={<AspenTemplate Page={<About />} />} />
        <Route path="/contact" element={<AspenTemplate Page={<Contact />} />} />
        <Route path="/login" element={<AspenTemplate Page={<Login />} />} />
        <Route path="/signup" element={<AspenTemplate Page={<Signup />} />} />
        <Route path="/pricing" element={<AspenTemplate Page={<Pricing />} />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

    </ThemeProvider>
  );
}
