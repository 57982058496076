import * as React from 'react';
import { useNavigate } from "react-router-dom";

//* MUI
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

interface ErrorProps {
  error: {
    title: string;
    description: string;
  };
}

export default function FeaturedPost(props: ErrorProps) {
  const { error } = props;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }

  const goHome = () => {
    navigate('/');
  }
  return (
    <CardActionArea component="a" sx={{ marginTop: 25 }}>
      <Card sx={{ display: 'flex', p: 10 }}>
        <CardContent sx={{ flex: 1 }}>
          <Typography component="h1" variant="h2">
            {error.title}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {error.description}
          </Typography>
          <Button onClick={goBack} sx={{ marginRight: 1, height: '37px' }} variant="outlined" size="medium" color="primary">
            Back
          </Button>
          <Button onClick={goHome} sx={{ marginRight: 1, height: '37px' }} variant="outlined" size="medium" color="primary">
            Home
          </Button>
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
          image='../../img/logos/aspen-logo.svg'
          alt='Aspen Logo'
        />
      </Card>
    </CardActionArea>
  );
}
