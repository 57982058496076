import * as React from 'react';
import { useNavigate } from "react-router-dom";

//* MUI
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

//* Icons
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';

interface NavProps {
  links: ReadonlyArray<{
    title?: string;
    url?: string;
  }>;
}

export default function Navigation(props: NavProps) {
  const { links } = props;
  const navigate = useNavigate();

  const toLogin = () => {
    navigate("/login");
  }

  const toSignUp = () => {
    navigate("/signup");
  }



  return (
    <React.Fragment>
      <Toolbar sx={{
        display: 'flex',
        marginTop: 0,
        borderBottom: 1,
        borderColor: 'divider',
        height: '8vh'
      }}>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
          <MenuIcon />
        </IconButton>
        <Grid container spacing={2}>
          {/* TODO IMPROVE FLEX HERE */}
          <Grid item xs={5} sm={7} md={4} lg={3} xl={2}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href="/"
              sx={{ p: 1, flexShrink: 0, textDecoration: 'none' }}
            >
              <CardMedia
                component="img"
                sx={{ width: 250, display: { sm: 'block' } }}
                image="../../img/logos/full-logo-transparent.png"
                alt="Aspen Logo"
              />
            </Link>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} md={4} lg={7} xl={7} mt={5}>
            {links.map((link) => (
              <Link
                color="inherit"
                noWrap
                key={link.title}
                variant="body2"
                href={link.url}
                sx={{ p: 1, flexShrink: 0, textDecoration: 'none' }}
              >
                {link.title}
              </Link>
            ))}
          </Grid>
          <Grid item xs={7} sm={5} md={4} lg={2} xl={3} mt={4.5} sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}>
            <Button onClick={toLogin} sx={{ marginRight: 1, height: '37px' }} variant="outlined" size="medium" color="success">
              Log In
            </Button>
            <Button onClick={toSignUp} sx={{ marginRight: 1, height: '37px' }} variant="outlined" size="medium" color="primary">
              Sign Up
            </Button>
            <Link
              variant="button"
              href="/support"
              sx={{ mt: 1, mx: 2 }}
            >
              Support
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
}
