import * as React from 'react';

//* MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import GlobalStyles from '@mui/material/GlobalStyles';

//* Components
import Copyright from './Copyright';

//* Variables
const footers = [
  {
    title: 'Network Diagnostics',
    items: [
      // { text: 'Team', link: "/team" },
      { text: 'About', link: "/about" },
      { text: 'Contact', link: "/contact" },
      { text: 'Support', link: "/support" },
    ],
  },
  {
    title: 'Aspen',
    items: [
      { text: 'Technology', link: "/technology" },
      { text: 'Features', link: "/features" },
      { text: 'Pricing', link: "/pricing" }
    ]
  },
  {
    title: 'Legal',
    items: [
      { text: 'Privacy Policy', link: "/privacy" },
      { text: 'Terms of Service', link: "/tos" }
    ],
  },
];

export default function Footer() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
        minHeight: '60vh',
      }}
    >
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <Box
        component="footer"
        sx={{
          pb: 1,
          pt: 4,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[300]
              : theme.palette.grey[900],
        }}
      >
        <Grid container spacing={4} mb={1} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom fontSize={16}>
                {footer.title}
              </Typography>
              <ul>
                {footer.items.map((item) => (
                  <li key={item.text}>
                    <Link href={item.link} variant="subtitle2" color="text.secondary" sx={{ textDecoration: 'none' }} fontSize={14}>
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </Box>
    </Box >
  );
}