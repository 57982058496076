import * as React from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';

//* MUI
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';

interface AppBarProps {
   open?: boolean;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
   zIndex: theme.zIndex.drawer + 1,
   transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   }),
}));

export default function Home() {
   const [state, setState] = React.useState({ open: true });
   const toggleDrawer = () => {
      setState({ open: !open });
   };

   return (
      <Box sx={{ display: 'flex' }}>
         <AppBar position="absolute" open={true}>
            <Toolbar
               sx={{
                  pr: '24px', // keep right padding when drawer closed
               }}
            >
               <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                     marginRight: '36px',
                     ...(state.open && { display: 'none' }),
                  }}
               >
                  <MenuIcon />
               </IconButton>
               <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
               >
                  Dashboard
               </Typography>
               <IconButton color="inherit">
                  <Badge badgeContent={4} color="secondary">
                     <NotificationsIcon />
                  </Badge>
               </IconButton>
            </Toolbar>
         </AppBar>
         <Drawer variant="permanent" open={state.open}>
            <Toolbar
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
               }}
            >
               <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
               </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
               {/* {mainListItems} */}
               <Divider sx={{ my: 1 }} />
               {/* {secondaryListItems} */}
            </List>
         </Drawer>
         <Box
            component="main"
            sx={{
               backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                     ? theme.palette.grey[100]
                     : theme.palette.grey[900],
               flexGrow: 1,
               height: '100vh',
               overflow: 'auto',
            }}
         >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
               <Grid container spacing={3}>
                  {/* Chart */}
                  <Grid item xs={12} md={8} lg={9}>
                     <Paper
                        sx={{
                           p: 2,
                           display: 'flex',
                           flexDirection: 'column',
                           height: 240,
                        }}
                     >
                        {/* <Chart /> */}
                     </Paper>
                  </Grid>
                  {/* Recent Deposits */}
                  <Grid item xs={12} md={4} lg={3}>
                     <Paper
                        sx={{
                           p: 2,
                           display: 'flex',
                           flexDirection: 'column',
                           height: 240,
                        }}
                     >
                        {/* <Deposits /> */}
                     </Paper>
                  </Grid>
                  {/* Recent Orders */}
                  <Grid item xs={12}>
                     <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {/* <Orders /> */}
                     </Paper>
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </Box>
   );
}